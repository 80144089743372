import Base from '../common/models/Base';

export default class Transport extends Base {
  svgPrefix = 'zapravka_svg__';

  bodyMap = {};

  fuelMap = {};

  constructor() {
    super('car', {
      haveCar: {
        type: 'radio',
        fieldConfig: {
          title: 'Есть ли у вас личный автомобиль:',
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          defaultValue: false,
          border: true,
        },
      },
      fuel: {
        type: 'card',
        fieldConfig: {
          title: 'Какой вид топлива потребляет авто:',
          items: [
            {
              label: 'Бензин',
              value: 'petrol',
              icon: 'petrol',
            },
            {
              label: 'Дизель',
              value: 'diesel',
              icon: 'diesel',
            },
            {
              label: 'Электро',
              value: 'electricity',
              icon: 'electro',
            },
          ],
          defaultValue: 'petrol',
          border: true,
        },
      },
      petrol_type: {
        type: 'select',
        name: 'Марка топлива:',
        fieldConfig: {
          border: true,
          hide: false,
          items: [
            { value: 'ai76', text: 'АИ76' },
            { value: 'ai92', text: 'АИ92' },
            { value: 'ai95', text: 'АИ95' },
            { value: 'ai98', text: 'АИ98' },
          ],
          defaultValue: 'ai95',
        },
      },
      body: {
        type: 'card',
        fieldConfig: {
          title: 'Тип кузова:',
          items: [
            {
              label: 'Легковая',
              value: 'common',
              icon: 'passengercar',
            },
            {
              label: 'Универсал',
              value: 'universal',
              icon: 'stationwagon',
            },
            {
              label: 'Внедорожник',
              value: 'suv',
              icon: 'suv',
            },
            {
              label: 'Минивен/Микроавтобус',
              value: 'minivan',
              icon: 'minivan',
            },
          ],
          defaultValue: 'common',
          border: true,
        },
      },
      yearOfIssue: {
        type: 'slider',
        fieldConfig: {
          title: 'Год выпуска автомобиля',
          type: Number,
          min: 1990,
          max: new Date().getFullYear(),
          unit: 'год',
          step: 10,
          border: true,
        },
      },
      mileage: {
        type: 'input',
        fieldConfig: {
          title: 'Количество потраченного топлива, литров/год*',
          type: Number,
          border: true,
          mask: '#####',
          subtitle: '*Без учета дальних путешествий. Их можно будет заполнить далее, на этапе "Путешествия".',
        },
      },
    },
    {
      haveCar: (model) => {
        const body = model.getValue('body');
        const fuel = model.getValue('fuel');
        this.fuelMap[fuel].classList.toggle('disable');
        this.bodyMap[body].classList.toggle('disable');
      },
      fuel: (model, value, prevValue) => {
        const haveCar = model.getValue('haveCar');
        const body = model.getValue('body');
        if (!haveCar || !body) {
          return;
        }
        model.setFieldConfigParam('petrol_type', 'hide', value !== 'petrol');
        if (prevValue) {
          this.fuelMap[prevValue].classList.toggle('disable');
        }
        this.fuelMap[value].classList.toggle('disable');
      },
      body: (model, value, prevValue) => {
        const haveCar = model.getValue('haveCar');
        if (!haveCar || !value) {
          return;
        }
        if (prevValue) {
          this.bodyMap[prevValue].classList.toggle('disable');
        }
        this.bodyMap[value].classList.toggle('disable');
      },
    });
    this.bodyMap = {
      minivan: document.querySelector(`#${this.svgPrefix}minivan`),
      common: document.querySelector(`#${this.svgPrefix}passenger`),
      suv: document.querySelector(`#${this.svgPrefix}suv`),
      universal: document.querySelector(`#${this.svgPrefix}universal`),
    };
    this.fuelMap = {
      petrol: document.querySelector(`#${this.svgPrefix}petrol`),
      electricity: document.querySelector(`#${this.svgPrefix}electricity`),
      diesel: document.querySelector(`#${this.svgPrefix}diesel`),
    };
  }
}
