<template>
  <div :class="['carbon-input-card', { 'set-border': border }]">
    <div class="carbon-input-card__title">
      <span v-text="title" />
    </div>
    <div class="carbon-input-card__icon">
      <base-icon v-if="icon" :name="icon" />
    </div>
    <v-divider class="carbon-input-card__divider" />
    <div class="carbon-input-card__input">
      <carbon-input :value="value" :description="description" :mask="mask" @input="input"/>
    </div>
  </div>
</template>

<script>

import CarbonInput from '@components/CarbonInput';

export default {
  name: 'CarbonInputCard',
  components: {
    CarbonInput,
  },
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    description: {
      type: String,
    },
    mask: {
      type: String,
      default: () => '####',
    },
    border: {
      type: Boolean,
    },
  },
  methods: {
    input(e) {
      this.$emit('input', e);
    },
  },
};
</script>

<style lang="sass">
  .carbon-input-card
    display: flex
    color: #FFFFFF
    background: #333232
    flex-direction: column
    align-items: center
    padding: 5px
    border-radius: 5px
    box-sizing: border-box
    border: 0.5px solid #00000000
    word-break: break-word
    &__divider
      background: #59A040
      border-width: 2px
      width: 100%
      margin-bottom: 5px
    &__icon
      min-height: 100px
      svg
        height: 100%
        width: 100%
</style>
