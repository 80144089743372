<template>
  <div v-show="!hide" :class="['carbon-selector', { 'set-border': border, disable: hide }]">
    <div class="carbon-selector__title">
      <span v-text="title" />
    </div>
    <div class="carbon-selector__contain">
      <div class="carbon-selector__contain__input">
        <v-select :value="defaultValue" dark :items="items" @change="e => $emit('change', e)"/>
      </div>
      <div v-if="description" class="carbon-input__contain__description">
        <span v-text="description" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CarbonSelector',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    border: {
      type: Boolean,
    },
    hide: {
      type: Boolean,
    },
    defaultValue: {
      type: [String, Number],
    },
  },
};
</script>

<style lang="sass">
  .carbon-selector
    display: flex
    color: #FFFFFF
    flex-direction: column
    align-items: center
    padding: 5px
    box-sizing: border-box
    word-break: break-word
    margin-bottom: 25px
    &__title
        width: auto
    &__contain
      display: flex
      margin: 0 10px
      justify-content: flex-end
      max-width: 150px
      &__input
        .theme--dark
          .v-input
            &__control
              .v-input__slot
                &::before
                  border-color: #4caf50

</style>
