import Base from '../common/models/Base';

export default class Food extends Base {
  svgPrefix = 'food_svg__';

  constructor() {
    super('Food', {
      ration: {
        type: 'card',
        fieldConfig: {
          title: 'Какой у вас рацион питания:',
          items: [
            {
              label: 'Сбалансированный',
              value: 'balance',
              icon: 'balance',
            },
            {
              label: 'Веган',
              value: 'vegan',
              icon: 'vegan',
            },
            {
              label: 'Лактоововеган',
              value: 'ovolactovegan',
              icon: 'l-o-veg',
            },
          ],
          defaultValue: 'balance',
          border: true,
        },
      },
      meat: {
        type: 'slider',
        fieldConfig: {
          title: 'Как часто вы едите мясо',
          min: 1,
          max: 7,
          underMin: false,
          border: true,
          step: 1,
          unit: 'раз',
          narrow: true,
        },
      },
    }, {
      ration: (model, value, prevValue) => {
        console.log(value);
        if (prevValue) {
          this.bodyMap[prevValue].classList.toggle('disable');
        }
        this.bodyMap[value].classList.toggle('disable');
      },
    });
    this.bodyMap = {
      balance: document.querySelector(`#${this.svgPrefix}balance`),
      vegan: document.querySelector(`#${this.svgPrefix}vegan`),
      ovolactovegan: document.querySelector(`#${this.svgPrefix}ovolactovegan`),
    };
  }
}
