<template>
  <div :class="['carbon-slider', { 'set-border': border }, { 'narrow': narrow}]">
    <div class="carbon-slider__title">
      <span v-text="title" />
    </div>
    <div class="carbon-slider__contain">
      <v-slider
        ref="slider"
        :min="min"
        :max="max"
        :value="value"
        :step="step"
        hide-details
        ticks="always"
        thumb-label="always"
        @input="input"
      >
        <template v-slot:thumb-label>
          {{getValue}}
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarbonSlider',
  props: {
    title: {
      type: String,
    },
    border: {
      type: Boolean,
    },
    value: {
      type: Number,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    unit: {
      type: String,
    },
    step: {
      type: Number,
    },
    narrow: {
      type: Boolean,
    },
    underMin: {
      type: Boolean,
    },
  },
  computed: {
    getValue() {
      const value = parseInt(this.value, 10);
      let prefix = '';

      if (value === this.max) {
        prefix = '>';
      }
      if (this.underMin !== false && this.min !== 0 && value === this.min) {
        prefix = '<';
      }
      return `${prefix} ${value} ${this.unit || ''}`;
    },
  },
  methods: {
    input(value) {
      this.$emit('input', parseInt(value, 10));
    },
  },
  mounted() {
    Object.defineProperty(this.$refs.slider, 'stepNumeric', {
      set: (value) => {
        this.stepNumeric = parseInt(value, 10);
      },
    });
    this.$refs.slider.stepNumeric = 1;
  },
};
</script>

<style lang="sass">
  .carbon-slider
    font-style: normal
    font-weight: normal
    font-size: 22px
    line-height: 1.5em
    text-align: center
    color: #FFFFFF
    margin-bottom: 25px
    &.narrow
      .carbon-slider__contain
        max-width: 446px
        width: -webkit-fill-available
        margin: 0 auto
    &__title
      margin-bottom: 50px
    .v-slider.v-slider--horizontal
      .v-slider__thumb-container:hover .v-slider__thumb:before,
      .v-slider__thumb-container--active .v-slider__thumb:before
        display: none !important
      .v-ripple__container
        display: none !important
      .v-slider
        &__thumb
          width: 28px
          height: 28px
          &-label
            transform: none !important
            margin-bottom: 25px
            background: #EBEBEB !important
            box-sizing: border-box
            border-radius: 5px !important
            color: #6FCF4D
            width: max-content !important
            min-width: 100px
            padding: 0 10px
            left: -41px
            &::after
              content: ""
              background: #EBEBEB
              border: 1px solid #ebebeb
              box-sizing: border-box
              border-radius: 0 0 0 15px
              width: 14px
              height: 14px
              bottom: -6px
              position: absolute
              transform: rotate(136deg)
            &::before
              content: ""
              background: #EBEBEB
              border: 1px solid #6FCF4D
              box-sizing: border-box
              border-radius: 0 0 0 15px
              width: 14px
              height: 14px
              position: absolute
              bottom: -7px
              z-index: 0
              transform: rotate(136deg)
            div
              transform: none !important
        &__track-container
          height: 10px
        &__ticks-container
          .v-slider__tick
            top: -4px !important
            height: 10px !important
            width: 3px !important
  @media only screen and (max-width: 640px)
    .carbon-slider
      font-size: 18px
</style>
