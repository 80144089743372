<template>
  <div :class="['carbon-radio', { 'set-border': border }]">
    <div class="carbon-radio__contain">
      <div class="carbon-radio__contain__title">
        <span v-text="title" />
      </div>
      <div class="carbon-radio__contain__radios">
        <v-radio-group :value="defaultValue" :row="!column" @change="change">
          <v-radio
            v-for="(item, key) in items"
            :key="key"
            color="#6FCF4D"
            dark
            :label="`${item.label}`"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div class="carbon-radio__slot">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarbonRadio',
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    border: {
      type: Boolean,
    },
    defaultValue: {
      type: [String, Boolean, Object],
    },
    column: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="sass">
  .carbon-radio
    display: flex
    flex-direction: column
    font-style: normal
    font-weight: normal
    font-size: 22px
    line-height: 1.5em
    text-align: center
    color: #FFFFFF
    margin-bottom: 25px
    &__contain
      display: flex
      align-items: baseline
      justify-content: space-between
      flex: 1 1 100%

  @media only screen and (max-width: 640px)
    .carbon-radio
      font-size: 18px
</style>
