<template>
  <div class="transport">
    <carbon-radio
      v-bind="model.getProps('haveCar')"
      @change="e => model.setValue('haveCar', e)"
    />
    <div :class="['transport__contain', {disable: !model.getValue('haveCar')}]">
      <carbon-radio-card
        v-bind="model.getProps('fuel')"
        @change="e => model.setValue('fuel', e)"
      />
      <carbon-selector
        v-bind="model.getProps('petrol_type')"
        @change="e => model.setValue('petrol_type', e)"
      />
      <carbon-radio-card
        class="body-cards"
        v-bind="model.getProps('body')"
        @change="e => model.setValue('body', e)"
      />
      <carbon-slider
        v-bind="model.getProps('yearOfIssue')"
        @input="e => model.setValue('yearOfIssue', e)"
      />
      <carbon-input
        v-bind="model.getProps('mileage')"
        @input="e => model.setValue('mileage', e)"
      />
    </div>
  </div>
</template>

<script>
import CarbonRadio from '@components/CarbonRadio';
import CarbonRadioCard from '@components/CarbonRadioCard';
import CarbonInput from '@components/CarbonInput';
import CarbonSlider from '@components/CarbonSlider';
import CarbonSelector from '@/common/components/CarbonSelector';
import Transport from '../../models/Transport';

export default {
  name: 'Transport',
  components: {
    CarbonSlider,
    CarbonRadio,
    CarbonRadioCard,
    CarbonInput,
    CarbonSelector,
  },
  computed: {
    model() {
      return this.$store.state.transportModel;
    },
  },
  created() {
    this.$store.commit('SET_MODEL_TRANSPORT', new Transport());
  },
};
</script>

<style lang="sass">
  .transport
    &__contain
      transition: opacity 1s ease-in-out
    .carbon-radio
      &__contain
        flex-direction: column
        .v-input
          .v-radio
            margin-bottom: 10px
    .carbon-slider
      &__contain
        display: flex
        justify-content: center
        .v-input
          max-width: 446px
          width: -webkit-fill-available
  @media only screen and (max-width: 1400px)
    .transport
      .body-cards
        .v-input--radio-group__input
          grid-template-rows: 1fr 1fr
</style>
