<template>
  <v-btn
    v-bind="{ color: 'rgba(84, 139, 62, 0.9)', ...vueBind }"
    class="carbon-btn"
    dark
    x-large
    :icon="Boolean(icon)"
    @click="click"
  >
    <v-icon>{{ icon }}</v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: 'CarbonButton',
  props: {
    vueBind: {
      type: Object,
      default: () => ({}),
    },
    text: {
      type: String,
    },
    type: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="sass">
.carbon-btn
  &.v-btn
    text-transform: none
    font-weight: normal
    line-height: 1.5em
    letter-spacing: 0.065em
</style>
