<template>
  <div :class="['carbon-radio-card', { 'set-border': border }]">
    <div class="carbon-radio-card__title">
      <span v-text="title" />
    </div>
    <div :class="['carbon-radio-card__contain']">
      <v-radio-group
        v-model="selectedItem"
        ref="radioGroup"
        dark
        @change="change"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          :class="['carbon-radio-card--item', { active: i === selectedItem }]"
          @click="setRadio(i)"
        >
          <div class="carbon-radio-card--item__radio">
            <v-radio :value="i" color="#6fcf4d" />
          </div>
          <div class="carbon-radio-card--item__icon">
            <base-icon v-if="item.icon" :name="item.icon" />
            <span v-else />
          </div>
          <v-divider class="radio-divider" />
          <div class="carbon-radio-card--item__text">
            <span v-text="item.label" />
          </div>
        </div>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import BaseIcon from '../../components/graphics/BaseIcon';

export default {
  components: { BaseIcon },
  name: 'CarbonRadioCard',
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    border: {
      type: Boolean,
    },
    width: {
      type: String,
    },
    justifyContent: {
      type: String,
    },
    alignItems: {
      type: String,
    },
    defaultValue: {
      type: [String, Number],
    },
  },
  data: () => ({
    selectedItem: 0,
  }),
  mounted() {
    this.$refs.radioGroup.$refs['input-slot'].style.width = this.width;
    this.$refs.radioGroup.$refs[
      'input-slot'
    ].parentElement.style.justifyContent = this.justifyContent;
    this.$refs.radioGroup.$refs['input-slot'].parentElement.style.alignItems = this.alignItems;
    if (this.defaultValue !== undefined) {
      this.change(this.items.findIndex((elem) => elem.value === this.defaultValue));
    } else {
      this.change(0);
    }
  },
  methods: {
    setRadio(index) {
      if (index === this.selectedItem) {
        return;
      }
      this.selectedItem = index;
      this.change(index);
    },
    change(index) {
      this.$emit('change', this.items[index].value);
    },
  },
};
</script>

<style lang="sass">
  .carbon-radio-card
    display: flex
    flex-direction: column
    margin-bottom: 25px
    color: #FFFFFF
    .radio-divider
      background: #59A040
      border-width: 2px
      width: 100%
      margin-bottom: 5px
    &__title
      margin-bottom: 10px
    &__contain
      .v-input__slot
        .v-input--radio-group__input
          display: grid
          grid-auto-flow: column
          grid-gap: 30px
          grid-template-rows: auto
          padding-bottom: 10px
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
          .carbon-radio-card--item
            cursor: pointer
            display: flex
            background: #333232
            flex-direction: column
            align-items: center
            padding: 5px
            border-radius: 5px
            box-sizing: border-box
            border: 0.5px solid #00000000
            word-break: break-word
            &__radio
              align-self: flex-end
            &__icon
              margin-bottom: 20px
              min-height: 50px
            &.active
              border: 0.5px solid #6fcf4d
              box-shadow: 0 0 10px #6fcf4d
              transition: box-shadow .5s ease-in-out
  @media only screen and (max-width: 640px)
    .carbon-radio-card
      &__contain
        .v-input__slot
          .v-input--radio-group__input
            grid-gap: 10px
</style>
