<template>
  <div class="travel">
    <carbon-radio
      v-bind="model.getProps('travel')"
      @change="e => model.setValue('travel', e)"
    />
    <div :class="['travel__card set-border', { disable: !model.getValue('travel') }]">
      <div class="travel__card--title">
        Какое расстояние вы преодолели за последний год на поезде или самолете?
      </div>
      <div class="travel__card--cards">
        <carbon-input-card
          v-bind="model.getProps('plane')"
          @input="e => model.setValue('plane', e)"
        />
        <carbon-input-card
          v-bind="model.getProps('train')"
          @input="e => model.setValue('train', e)"
        />
        <carbon-input-card
          v-bind="model.getProps('car')"
          @input="e => model.setValue('car', e)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CarbonInputCard from '@components/CarbonInputCard';
import CarbonRadio from '@components/CarbonRadio';
import Travel from '../../models/Travel';

export default {
  name: 'Travel',
  components: {
    CarbonInputCard,
    CarbonRadio,
  },
  computed: {
    model() {
      return this.$store.state.travelModel;
    },
  },
  created() {
    this.$store.commit('SET_MODEL_TRAVEL', new Travel());
  },
};
</script>

<style lang="sass">
  .travel
    color: #FFFFFF
    &__card
      display: flex
      flex-direction: column
      transition: opacity 1s ease-in-out
      &--title
        margin-bottom: 10px
      &--cards
        display: grid
        grid-auto-flow: row
        grid-gap: 10px
        grid-template-columns: repeat(auto-fit, minmax(100px, 2fr))
</style>
