<template>
  <div class="food">
    <carbon-radio-card
      v-bind="model.getProps('ration')"
      @change="(e) => model.setValue('ration', e)"
    />
    <carbon-slider
      v-bind="model.getProps('meat')"
      :class="['meat-slider', { disable: isVegan}]"
      @input="(e) => model.setValue('meat', e)"
    />
  </div>
</template>

<script>
import CarbonRadioCard from '@components/CarbonRadioCard';
import CarbonSlider from '@components/CarbonSlider';
import Food from '../../models/Food';

export default {
  name: 'Food',
  components: { CarbonRadioCard, CarbonSlider },
  computed: {
    model() {
      return this.$store.state.foodModel;
    },
    isVegan() {
      const ration = this.model.getValue('ration');
      return ration !== 'balance';
    },
  },
  created() {
    this.$store.commit('SET_MODEL_FOOD', new Food());
  },
};
</script>

<style lang="sass">
.food
  .meat-slider
    transition: opacity 1s ease-in-out
</style>
