<template>
  <div class="logistics">
    <carbon-input
      v-for="(item, key) in getKeys"
      :key="key"
      v-bind="model.getProps(item)"
      @input="(e) => model.setValue(item, e)"
    />
  </div>
</template>

<script>
import CarbonInput from '@components/CarbonInput';
import Logistics from '../../models/Logistics';

export default {
  name: 'Logistics',
  components: {
    CarbonInput,
  },

  computed: {
    getKeys() {
      return Object.keys(this.model.values);
    },

    model() {
      return this.$store.state.logisticsModel;
    },
  },
  created() {
    this.$store.commit('SET_MODEL_LOGISTICS', new Logistics());
  },
};
</script>

<style lang="sass">
  .logistics
    .carbon-input__contain
      align-content: center
      max-width: fit-content
      &__input
        max-width: 60px
        margin-right: 50px
      .v-input
        margin: 0 auto
  @media only screen and (max-width: 640px)
    .logistics
      .carbon-input
        &__title
          word-break: break-word
          flex-basis: 40%
        &__contain
          flex-basis: 60%
</style>
