import Base from '@/common/models/Base';
import vuetify from '@/plugins/vuetify';

export default class House extends Base {
  svgPrefix = 'home_svg__';

  order = [
    'man9',
    'man8',
    'man7',
    'man6',
    'man5',
    'man4',
    'man3',
    'man2',
    'man1',
  ];

  constructor() {
    super('House', {
      house_area: {
        type: 'input',
        fieldConfig: {
          title: 'Площадь жилья:',
          description: 'м²',
          type: Number,
          min: 0,
          max: 200,
          border: true,
        },
      },
      energy_effectivity_class: {
        type: 'select',
        name: 'Класс энергоэффективности дома:',
        fieldConfig: {
          border: true,
          items: [
            { value: 'a_plus_plus', text: 'A++' },
            { value: 'a_plus', text: 'A+' },
            { value: 'a', text: 'A' },
            { value: 'b', text: 'B' },
            { value: 'c', text: 'C' },
            { value: 'd', text: 'D' },
            { value: 'e', text: 'E' },
            { value: 'f', text: 'F' },
          ],
          defaultValue: 'a_plus_plus',
        },
      },
      people: {
        type: 'slider',
        fieldConfig: {
          title: 'Сколько человек живет в доме:',
          min: 1,
          max: 10,
          border: true,
          type: Number,
          mask: '##',
          narrow: true,
        },
      },
      animal: {
        type: 'radio',
        fieldConfig: {
          title: 'Есть ли животное в доме:',
          defaultValue: false,
          column: vuetify.framework.breakpoint.mobile,
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          border: true,
        },
      },
      cat: {
        type: 'input',
        fieldConfig: {
          type: Number,
        },
      },
      dog: {
        type: 'input',
        fieldConfig: {
          type: Number,
        },
      },
      rodent: {
        type: 'input',
        fieldConfig: {
          type: Number,
        },
      },
      electricity: {
        type: 'slider',
        fieldConfig: {
          title: 'Потребление электричества',
          type: Number,
          min: 200,
          max: 4000,
          unit: 'кВт*ч/год',
          step: 200,
          narrow: true,
        },
      },
      gas: {
        type: 'slider',
        fieldConfig: {
          title: 'Потребление газа',
          min: 0,
          max: 500,
          unit: 'м³/год',
          step: 25,
          type: Number,
          narrow: true,
        },
      },
      water: {
        type: 'slider',
        fieldConfig: {
          title: 'Потребление воды',
          min: 0,
          max: 500,
          unit: 'м³/год',
          step: 25,
          narrow: true,
        },
      },
      heating: {
        type: 'card',
        fieldConfig: {
          title: 'Тип отопления в доме:',
          items: [
            {
              label: 'Газ',
              value: 'gas',
              icon: 'gasheating',
            },
            {
              label: 'Центральная система отопления',
              value: 'central_heating',
              icon: 'centralheating',
            },
          ],
          defaultValue: 'gas',
          width: 'auto',
          alignItems: 'center',
          border: true,
        },
      },
      additional: {
        type: 'card',
        fieldConfig: {
          title: 'Что есть в вашем доме:',
          items: [
            {
              label: 'Плита газовая',
              value: 'gas_stove',
              icon: 'gastove',
            },
            {
              label: 'Плита электрическая',
              value: 'electric_stove',
              icon: 'elstove',
            },
          ],
          defaultValue: 'gas_stove',
          width: 'auto',
          alignItems: 'center',
        },
      },
      boiler: {
        type: 'radio',
        fieldConfig: {
          title: 'Водонагреватель:',
          column: vuetify.framework.breakpoint.mobile,
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          defaultValue: false,
        },
      },
    }, {
      animal: (model, value) => {
        const dog = document.querySelector(`#${this.svgPrefix}dog`);
        const cat = document.querySelector(`#${this.svgPrefix}cat`);
        if (!value) {
          dog.classList.add('disable');
          cat.classList.add('disable');
          return;
        }
        if (model.getValue('dog')) {
          dog.classList.remove('disable');
        }
        if (model.getValue('cat')) {
          cat.classList.remove('disable');
        }
      },
      boiler: () => {
        const boilerSvg = document.querySelector(`#${this.svgPrefix}boiler`);
        boilerSvg.classList.toggle('disable');
      },
      additional: (_, value) => {
        console.log(value);
        const selected = value === 'gas_stove' ? 'gasstove' : 'stove';
        const notSelected = value !== 'gas_stove' ? 'gasstove' : 'stove';
        const obj = {
          gasstove: document.querySelector(`#${this.svgPrefix}gasstove`),
          stove: document.querySelector(`#${this.svgPrefix}stove`),
        };
        console.log(selected, notSelected);
        obj[selected].classList.remove('disable');
        obj[notSelected].classList.add('disable');
      },
      people: (_, value) => {
        this.order.forEach((it, index) => {
          const fn = index + 1 >= value ? 'add' : 'remove';
          document.querySelector(`#${this.svgPrefix}${it}`).classList[fn]('disable');
        });
      },
      dog: (_, value) => {
        const dog = document.querySelector(`#${this.svgPrefix}dog`);
        if (value) {
          dog.classList.remove('disable');
        } else {
          dog.classList.add('disable');
        }
      },
      cat: (_, value) => {
        const cat = document.querySelector(`#${this.svgPrefix}cat`);
        if (value) {
          cat.classList.remove('disable');
        } else {
          cat.classList.add('disable');
        }
      },
    });
  }

  onlyPeople = (id) => !/cat|dog/.test(id)
}
